<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="inner-container">
            <div class="modal-header-container">
              <div class="label">
                <h2><slot name="header"> default header </slot></h2>
              </div>

              <div @click="$emit('close')" class="close-icon">
                <i class="fa fa-window-close"></i>
              </div>
            </div>

            <div class="modal-body">
              <slot name="body"> default body </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    .modal-container {
      max-width: 500px;
      max-height: 100%;
      overflow: scroll;
      margin: 0px auto;
      padding: 20px 30px;
      border-radius: 2px;

      transition: all 0.3s ease;
      font-family: Helvetica, Arial, sans-serif;
      .inner-container {
        border-radius: 8px;
        background-color: lightgray;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        .modal-header-container {
          display: grid;
          grid-template-columns: 1fr 50px;
          padding-left: 20px;
          border-bottom: solid 1px #3396d1;
          .label {
            display: flex;
            align-items: center;
          }
          .close-icon {
            margin-right: 7px;
            display: flex;
            justify-content: center;
            font-size: 50px;
            opacity: 1;
            .fa {
              cursor: pointer;
              color: #8e8e8e;
            }
            &:hover {
              .fa {
                color: #1278c0;
              }
            }
          }
        }
      }
      .modal-body {
        background-color: white;
      }
    }
  }
}
</style>
